.navbar {
    position: relative;
    display: flex;
    user-select: none;
    width: 100%;
    align-items: center;
    height: 4rem;
    letter-spacing: 2.5px;
    background-color: var(--second-bg);
    color: var(--primary-color);
    box-shadow: var(--dark-shadow); 
}
.navbar__content {
    display: flex;
    justify-content:space-between;
    align-items: center;
    width: 100%;
}
.navbar__switch {
    position: fixed;
    right: 15px;
}
.ant-switch-checked {
    background: var(--primary-color) !important;
}
.navbar__logo > a {
    border: 0;
}
.nav-list {
    display: flex;
}
.nav-list>div:not(:last-child){
    margin-right: 2rem;
}
.navbar__net {
    margin-right: 4rem !important;
}
.hbar__logo {
    font-size: 25px;
    color: var(--primary-blue);
}