* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@400;600;700&display=swap');
html {
    height: 100%;
}
a{
    text-decoration: none !important;
    /* color: var(--primary-color) !important; */
    color: inherit !important;
}
/* a:hover{
    border-bottom: 1px solid var(--primary-color);
} */

li {
    list-style-type: none;
}
body {
    margin: 0 !important;
    font-family: 'Quicksand', sans-serif !important;
    font-style: normal !important;
    background-color: var(--main-bg) !important;
    color: var(--main-bg);
    font-size: 16px !important;
    font-weight: 600 !important;
    height: 100% !important;
    line-height: 1;
}

main {
    flex: 1 0 auto;
}
footer{
    flex: 0 0 auto;
    width: 100%;
    margin-top: 4rem;
}

#root {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.wrapper {
    max-width: 1240px;
    margin: 0px auto;
    padding: 0px 50px;
    box-sizing: content-box;
}
.page {
    min-height: 200px;
    border-radius: 15px;
    padding: 30px !important;
    background-color: var(--primary-color);
    box-shadow: var(--light-shadow);
    color: var(--text-color);
}
.link{
    color: var(--primary-blue);
}
.content {
    margin-top: 1rem;
}
.loader{
    position: absolute;
    width: 100vw;
    height: 92vh;
    top: 8vh;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100;
}
.error {
    font-size: 18px;
    font-style: italic;
}

html[data-theme = 'light'] {
    --main-bg: rgb(230, 231, 233);
    --second-bg: rgb(34, 34, 34);
    --primary-color: rgb(249, 249, 249);
    --primary-blue: rgb(32, 138, 188);
    --hover-color: #36c0df;
    --dark-shadow: 0px 0px 12px 4px rgba(0, 4, 38, 0.3);
    --light-shadow: 0px 0px 12px 2px rgba(0, 4, 38, 0.3);
}

html[data-theme = 'dark'] {
    --main-bg: rgb(34, 34, 34);
    /* --primary-color: rgb(209, 148, 35); */
    --primary-color: rgb(255, 76, 126);
    --hover-color : #df3666;
    --text-color: rgb(249, 249, 249);
    /* --dark-shadow: 0px 0px 12px 4px rgba(199, 198, 198, 0.3); */
    --dark-shadow: 0px 0px 12px 4px rgba(255, 76, 126, 0.2);
    --light-shadow: 0px 0px 12px 2px rgba(255, 76, 127, 0.2);
}

::-webkit-scrollbar {
    width: 0;
}
